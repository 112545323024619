import {
  ActionReducerMapBuilder,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';

import { NAME as ENTRIES_NAME } from 'src/features/entries/constants';
import { entriesAdapter } from 'src/features/entries/entriesSlice';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IProof } from 'src/ts/interfaces';
import { NAME } from './constants';
import {
  getProofByIdThunk,
  getProofsByEntryIdThunk,
  getProofTextBreakupThunk,
  updateProofThunk
} from './services';

const proofsAdapter = createEntityAdapter<IProof>({
  selectId: (proof) => proof.id
});

interface StateData extends EntityState<IProof> {
  status: StatusTypes;
  error?: string | null;
}

const initialState = proofsAdapter.getInitialState({
  status: StatusTypes.IDLE,
  error: null
});

const proofsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      };
    },
    onUpdateCheckProof: (
      state: StateData,
      action: PayloadAction<{ proofId: string; isChecked: boolean }>
    ) => {
      const { proofId, isChecked } = action.payload;
      proofsAdapter.updateOne(state, { id: proofId, changes: { isChecked } });
    },
    onUpdateCheckProofs: (state: StateData, action: PayloadAction<{ isChecked: boolean }>) => {
      const { isChecked } = action.payload;
      const data = proofsAdapter.getSelectors().selectAll(state);

      for (const entries of data) {
        proofsAdapter.updateOne(state, { id: entries.id, changes: { isChecked } });
      }
    },
    setLoadingProof: (
      state: StateData,
      action: PayloadAction<{ proofId: string; isLoading: boolean }>
    ) => {
      const { proofId, isLoading } = action.payload;
      proofsAdapter.updateOne(state, { id: proofId, changes: { isLoading } });
    }
  },
  extraReducers: (builder) => {
    getProofsByEntryIdReducer(builder);
    getProofReducer(builder);
    updateProofReducer(builder);
    getProofTextBreakupReducer(builder);
  }
});

const getProofsByEntryIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getProofsByEntryIdThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(getProofsByEntryIdThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const proofs = (response?.data || []) as IProof[];

    proofsAdapter.upsertMany(
      state,
      proofs?.map((proof, index) => {
        const position = (index + 1).toString();
        return {
          ...proof,
          fileName: 'Document ' + position
        };
      }) || []
    );
    state.status = StatusTypes.SUCCESS;
  });
  builder.addCase(getProofsByEntryIdThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
};

const getProofReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getProofByIdThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(getProofByIdThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const proof = (response?.data || {}) as IProof;

    proofsAdapter.addOne(state, proof);
    state.status = StatusTypes.SUCCESS;
  });
  builder.addCase(getProofByIdThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
};

const updateProofReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(updateProofThunk.pending, (state) => {
    state.error = null;
  });
  builder.addCase(updateProofThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const proof = (response?.data || {}) as IProof;

    proofsAdapter.upsertOne(state, { ...proof, isLoading: false });

    state.status = StatusTypes.SUCCESS;
  });
  builder.addCase(updateProofThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
};

const getProofTextBreakupReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getProofTextBreakupThunk.pending, (state, action) => {
    const { id } = action.meta.arg;
    if (id) {
      const changes = {
        text_breakups_status: StatusTypes.LOADING,
        text_breakups_error: undefined
      };
      proofsAdapter.updateOne(state, { id, changes });
    }
  });

  builder.addCase(getProofTextBreakupThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const { id } = action.meta.arg;
    if (id) {
      const changes = {
        text_breakups_status: StatusTypes.SUCCESS,
        text_breakups_error: undefined,
        text_breakups: response.text_breakups
      };
      proofsAdapter.updateOne(state, { id, changes });
    }
  });

  builder.addCase(getProofTextBreakupThunk.rejected, (state, action) => {
    const { id } = action.meta.arg;
    if (id) {
      const changes = {
        text_breakups_status: StatusTypes.ERROR,
        text_breakups_error: action.error.message
      };
      proofsAdapter.updateOne(state, { id, changes });
    }
  });
};

const selectAllByEntryId = createSelector(
  [
    proofsAdapter.getSelectors((state: RootState) => state[NAME]).selectAll,
    entriesAdapter.getSelectors((state: RootState) => state[ENTRIES_NAME]).selectById
  ],
  (proofs, entry) => proofs?.filter((proof) => proof.entry_id === entry?.id) || []
);

export const selectors = {
  ...proofsAdapter.getSelectors((state: RootState) => state[NAME]),
  selectAllByEntryId,
  status: (state: RootState) => state[NAME].status
};

export const { reducer, actions } = proofsSlice;
