import ButtonLink from 'src/components/atoms/ButtonLink';
import { Icon } from 'src/components/atoms/Icon';
import TableCell from 'src/components/atoms/TableCell';
import { default as Tag, TagProps } from 'src/components/atoms/Tag';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components/macro';

import {
  getResultStatusColorTag,
  getStatusColor,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';
import { EntryResultType, EntryStatusType } from 'src/ts/interfaces';

export const Header = styled.div`
  display: flex;
  padding-left: 5px;

  & > div {
    margin-right: 6rem;
    margin-bottom: 0.75rem;
  }

  & > div:last-child {
    margin-left: auto;
    margin-right: 0;
    justify-content: flex-end;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  width: 180px;

  & > div {
    margin-right: 1rem;
  }

  & > h4 {
    color: ${({ theme }) => theme.color.gray[900]};
  }
`;

export const StyledText = styled(Text)`
  min-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
`;

export const StyledButton = styled(ButtonLink)`
  justify-content: center;
  font-weight: 500;
  font-size: 0.875rem;
  gap: 0.75rem;
`;

export const StyledIcon = styled(Icon)`
  margin-left: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 30px;

  .monthly-gross-income-tooltip {
    display: inline-flex;
    padding-left: 10px;
  }

  .align-download-button {
    display: inline-flex;
    vertical-align: middle;
  }
`;

export const ProofCount = styled.div`
  display: inline-flex;
`;
export const DocViewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.color.blue[700]};

  &[aria-disabled='true'] {
    cursor: unset;
    color: ${({ theme }) => theme.color.gray[300]};

    & > div > div > svg {
      fill: ${({ theme }) => theme.color.gray[300]};
    }
  }
`;

export const StatusLabelCell = styled(TableCell)<{ status: EntryStatusType }>`
  color: ${getStatusColor} !important;
`;

interface StyledTagProps extends TagProps {
  cleanProceedWithCautionFlagEnabled?: boolean;
}

export const StyledTag = styled(Tag).attrs((props: StyledTagProps) => ({
  label: transformResultToReadable(props.label),
  color: getResultStatusColorTag(
    props.label as EntryResultType,
    props.cleanProceedWithCautionFlagEnabled
  ),
  showBackground: true
}))<StyledTagProps>``;

export const MonthlyGrossIncomeCell = styled.div`
  display: flex;
  align-items: center;
`;
