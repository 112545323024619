import { envVars } from 'src/constants/config';
import { IConstants } from 'src/ts/interfaces';

const overrideUrls = (constants: IConstants, host: string): IConstants => {
  // Drop the first subdomain, if present, so it works for interpolation
  const hostParts = host.split('.');
  let hostBase = hostParts.slice(hostParts.length > 2 ? 1 : 0).join('.');
  let docPortalHost = 'documentportal.info';
  if (/\.dev\./.test(host)) docPortalHost = `dev.${docPortalHost}`;
  if (/\.test\./.test(host)) docPortalHost = `test.${docPortalHost}`;
  if (/\.uat\./.test(host)) docPortalHost = `uat.${docPortalHost}`;
  if (/\.demo\./.test(host)) docPortalHost = `demo.${docPortalHost}`;

  // If this host _is_ a docportal address, we've gotta replace hostBase with
  // the snappt.com equivalent (e.g. dev.documentportal.info > dev.snappt.com)
  if (docPortalHost === hostBase) {
    hostBase = host.replace('documentportal.info', 'snappt.com');
  }

  const urls = {
    document_portal: `https://${docPortalHost}`,
    server_url: `https://fraud-api.${hostBase}`,
    frontend_url: `https://fraud.${hostBase}`,
    api_url: `https://fraud-api.${hostBase}/v2`,
    epa_url: `https://enterprise-api.${hostBase}`,
    feature_flag_api_url: `https://feature-flags.${hostBase}`
  };

  // Clone and override
  const clone = structuredClone(constants);
  delete clone.bucket_url; // Not used, just delete it.
  return Object.assign({}, clone, urls);
};

const configVars = (): IConstants => {
  const service = window.location.hostname;
  switch (service) {
    case 'wintheknifefight':
      return envVars.wintheknifefightDev;
    case 'fraud.snappt.com':
    case 'documentportal.info':
    case 'client.snappt.com':
      return envVars.production;
    case 'fraud-sandbox.snappt.com':
    case 'sandbox.documentportal.info':
      return envVars.sandbox;
    case 'fraud-ralph.snappt.com':
    case 'ralph.documentportal.info':
      return envVars.ralph;
    case 'fraud-demo.snappt.com':
    case 'demo.documentportal.info':
      return envVars.demo;
    case 'dev.documentportal.info':
    case 'fraud.dev.snappt.com':
    case 'fraud.dev.wintheknifefight.com':
      return overrideUrls(envVars.ralph, service);
    case 'test.documentportal.info':
    case 'fraud.test.snappt.com':
    case 'fraud.test.wintheknifefight.com':
      return overrideUrls(envVars.sandbox, service);
    // Note: same as test right now, but separating in case we want them to be
    // different in the future.
    case 'uat.documentportal.info':
    case 'fraud.uat.snappt.com':
    case 'fraud.uat.wintheknifefight.com':
      return overrideUrls(envVars.sandbox, service);
    default:
      return envVars.development;
  }
};

export default configVars();
