import styled from 'styled-components/macro';

import { Icon } from 'src/components/atoms/Icon';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';

import { IIncomeCalculationStatusDetails } from 'src/ts/interfaces';

export const StyledTooltip = styled(ToolTip)`
  display: inline-flex;
  padding-left: 6px;
  padding-top: 5px;
  svg {
    height: 15px;
    width: 15px;
  }
`;

const shouldShowNotAvailableTooltip = (
  isGrossIncomeAvailable: boolean,
  incomeVerificationStatusDetails?: IIncomeCalculationStatusDetails
) => !isGrossIncomeAvailable && Boolean(incomeVerificationStatusDetails?.length);

const IVTooltip = ({
  incomeVerificationStatusDetails,
  isGrossIncomeAvailable
}: {
  incomeVerificationStatusDetails?: IIncomeCalculationStatusDetails;
  isGrossIncomeAvailable: boolean;
}): JSX.Element | null => {
  if (!shouldShowNotAvailableTooltip(isGrossIncomeAvailable, incomeVerificationStatusDetails)) {
    return null;
  }

  return (
    <StyledTooltip
      direction={ToolTipDirection.top}
      content={incomeVerificationStatusDetails?.map((detail) => detail.message).join('\n')}
    >
      <Icon icon="help" />
    </StyledTooltip>
  );
};

export default IVTooltip;
