export const NAME = 'incomeVerification';
export const INCOME_CALCULATION_PATH = `${NAME}/calculateIncome`;
export const SEND_REPORT_PATH = `${NAME}/sendReport`;
export const DISPUTE_PATH = `${NAME}/dispute`;

type ErrorWarningMapping = {
  type: 'warning' | 'error';
  message: string;
};

export const ERROR_WARNING_MAPPINGS: Record<number, ErrorWarningMapping> = {
  2000: {
    type: 'warning',
    message: 'Gross pay is missing from one or more documents.'
  },
  2006: {
    type: 'warning',
    message: 'The pay start and/or end dates are missing from one or more documents.'
  },
  2008: {
    type: 'warning',
    message: 'Income Source is missing from one or more documents.'
  },
  3001: {
    type: 'error',
    message: 'Gross pay is missing from all documents.'
  },
  3002: {
    type: 'error',
    message: 'Recipient Name is missing from one or more documents.'
  },
  3003: {
    type: 'error',
    message: 'Recipient name does not match across all documents.'
  },
  3004: {
    type: 'error',
    message: 'Documents do not span a minimum of 28 consecutive days.'
  },
  3005: {
    type: 'error',
    message: 'Documents do not represent pay issued within the past 90 days.'
  },
  3007: {
    type: 'error',
    message: 'The pay start and/or end dates are missing from all documents.'
  }
};
