export const STATUS_PROCESSING_PROOF = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  IN_PROCESS: 'IN_PROCESS',
  IN_QUEUE: 'IN_QUEUE'
};

export const STATUS_PROCESSING_UPLOAD = [
  STATUS_PROCESSING_PROOF.IN_QUEUE,
  STATUS_PROCESSING_PROOF.IN_PROCESS
];

export const POLLING = {
  MAX_ATTEMPTS: 18,
  INTERVAL: 5000, // 5 secs
  STATUS_TO_STOP: [STATUS_PROCESSING_PROOF.SUCCESS, STATUS_PROCESSING_PROOF.FAILED]
};

export const FILES_TO_UPLOAD = {
  MAX_FILES: 5,
  NOT_ALLOW_DOC_EXTENSIONS: ['doc', 'docx'],
  ALLOW_TYPES: ['application/pdf'],
  ALLOW_FORMAT: '.pdf',
  MAX_FILE_NAME_LENGTH: 260,
  // eslint-disable-next-line no-useless-escape
  NAME_REGX: /^[_a-zA-Z0-9][a-zA-Z0-9@. ~_\(\)\[\]\{\}-]*$/
};

export const PRINT_TO_PDF_CONTEXTS = [
  'chrome',
  'safari',
  'quartz pdfcontext',
  'microsoft edge',
  'print to pdf',
  'pdfium',
  'acrobat distiller',
  'pdf meld',
  'acrobat web capture',
  'mozilla'
];
