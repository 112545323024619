import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { DisputeCalculationPayload } from 'src/ts/interfaces/incomeVerification';
import { ERROR_WARNING_MAPPINGS, NAME } from './constants';
import { calculateIncomeThunk, disputeCalculationThunk } from './services';

type IncomeCalculationSuccessResponse = {
  results: {
    status_code: string;
    monthly_income: number;
    reason_codes: number[];
  };
};

type IncomeCalculationFailedResponse = {
  status_code: string;
  reason_codes: number[];
};

type StateData = {
  calculateIncome: {
    data?: IncomeCalculationSuccessResponse | IncomeCalculationFailedResponse | null;
    error?: string | null;
    status: StatusTypes;
  };
  disputes: {
    data: DisputeCalculationPayload | null;
    status: StatusTypes;
    error?: string | null;
  };
};

const initialState: StateData = {
  calculateIncome: {
    data: null,
    error: null,
    status: StatusTypes.IDLE
  },
  disputes: {
    data: null,
    status: StatusTypes.IDLE,
    error: null
  }
};

const incomeVerificationSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<StateData>) => {
    builder.addCase(calculateIncomeThunk.pending, (state, action) => {
      state.calculateIncome.data = null;
      state.calculateIncome.error = null;
      state.calculateIncome.status = StatusTypes.LOADING;
    });
    builder.addCase(calculateIncomeThunk.fulfilled, (state, action) => {
      state.calculateIncome.data = action.payload;
      state.calculateIncome.status = StatusTypes.SUCCESS;
    });
    builder.addCase(calculateIncomeThunk.rejected, (state, action) => {
      state.calculateIncome.error = action.error?.message;
      state.calculateIncome.status = StatusTypes.ERROR;
    });

    builder.addCase(disputeCalculationThunk.pending, (state) => {
      state.disputes.data = null;
      state.disputes.error = null;
      state.disputes.status = StatusTypes.LOADING;
    });
    builder.addCase(disputeCalculationThunk.fulfilled, (state, action) => {
      state.disputes.data = action.payload.data as DisputeCalculationPayload;
      state.disputes.status = StatusTypes.SUCCESS;
    });
    builder.addCase(disputeCalculationThunk.rejected, (state, action) => {
      state.disputes.error = action.error?.message;
      state.disputes.status = StatusTypes.ERROR;
    });
  }
});

const reasonMessages = (reasons: number[]) =>
  reasons
    .map((reason: number) => ERROR_WARNING_MAPPINGS[reason])
    .filter((reason) => reason != null);

export const selectors = {
  selectCalculateIncome: (state: RootState) => state[NAME].calculateIncome,
  selectCalculateIncomeData: (state: RootState) => {
    const data = state[NAME].calculateIncome.data;

    if (!data) return null;

    if ('results' in data) {
      return {
        status: data.results?.status_code,
        data: data.results?.monthly_income.toFixed(2),
        reasons: reasonMessages(data.results?.reason_codes ?? []),
        reason_codes: data.results?.reason_codes ?? []
      };
    } else {
      return {
        status: data?.status_code,
        reasons: reasonMessages(data.reason_codes ?? []),
        reason_codes: data.reason_codes
      };
    }
  },
  selectCalculateIncomeError: (state: RootState) => state[NAME].calculateIncome.error,
  selectCalculateIncomeStatus: (state: RootState) => state[NAME].calculateIncome.status,
  isIdle: (state: RootState) => state[NAME].calculateIncome.status === StatusTypes.IDLE,
  isLoading: (state: RootState) => state[NAME].calculateIncome.status === StatusTypes.LOADING,
  isSuccess: (state: RootState) => state[NAME].calculateIncome.status === StatusTypes.SUCCESS,
  isError: (state: RootState) => state[NAME].calculateIncome.status === StatusTypes.ERROR,
  isDisputeLoading: (state: RootState) => state[NAME].disputes.status === StatusTypes.LOADING
};

export const { reducer, actions } = incomeVerificationSlice;
