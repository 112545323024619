import { useSelector } from 'react-redux';

import { selectors as authSelectors } from 'src/features/auth';
import { selectors as propertySelectors } from 'src/features/property';
import { getActiveRouteOptions } from 'src/router/navOptions';
import { INavOption } from 'src/ts/interfaces/navOption';

const useNavOptionPermissions = (nameOption: string) => {
  const hasIdentityVerificationPropertyEnabled =
    useSelector(propertySelectors.hasIdentityVerificationPropertyEnabled.data) ?? false;

  const authUser = useSelector(authSelectors.user);

  const isDocumentLinksTabEnabled = !authUser?.properties?.every(
    (property) => property.disable_dup_fd
  );

  const navOptions = getActiveRouteOptions(
    hasIdentityVerificationPropertyEnabled,
    isDocumentLinksTabEnabled
  );
  const option = navOptions.find((option) => option?.name === nameOption);

  return (option as INavOption)?.permissions;
};

export default useNavOptionPermissions;
