// Vendor
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

// Components
import { Loader } from 'src/components/atoms/Loader';
import { ShowState } from 'src/components/molecules/ShowState';
import { PropertyForm } from 'src/components/organisms/PropertyForm';
import { PropertyMultitab } from 'src/components/organisms/PropertyMultitab';
import { DetailView } from 'src/components/templates/DetailView';
import { PropertyUsersPage } from '../PropertyUsersPage';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useCompany from 'src/features/company/hooks/useCompany';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import useProperty from '../../features/property/hooks/useProperty';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';
import { selectors as propertyFeatureSelectors } from 'src/features/propertyFeature/propertyFeatureSlice';

// Enums
import { PropertyStatus } from 'src/ts/enums';

// Types
import usePropertyFeature from 'src/features/propertyFeature/hooks/usePropertyFeature';
import { IProperty, LocationState } from 'src/ts/interfaces';

const PropertyEditPage: React.FC = () => {
  const { propertyId } = useParams();
  const location = useLocation();

  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const [tabIndex, setTabIndex] = useState(0);

  const { isAdmin, isBuildingManager, isCompanyAdmin } = useRole();
  const { onGetOneById: onGetCompanyById } = useCompany();

  const companyData = useSelector(companySelector.getOne.data);
  const companyIsLoading = useSelector(companySelector.getOne.isLoading);
  const companyError = useSelector(companySelector.getOne.error);

  const {
    onGetById: onGetProperty,
    onResetGetById: onResetGetProperty,
    onUpdate: onUpdateProperty,
    onGetAll: onGetAllProperty,
    onResetUpdate
  } = useProperty();

  const { onGetAll: onGetPropertyFeatures, onUpdatePropertyFeatures } = usePropertyFeature();

  const getPropertyData = useSelector(propertySelectors.getOne.data);
  const getPropertyIsIdle = useSelector(propertySelectors.getOne.isIdle);
  const getPropertyIsLoading = useSelector(propertySelectors.getOne.isLoading);
  const getPropertyError = useSelector(propertySelectors.getOne.error);

  const updatePropertyError = useSelector(propertySelectors.update.error);
  const updatePropertyIsSuccessful = useSelector(propertySelectors.update.isUpdated);

  const createPropertyIsSuccessful = useSelector(propertySelectors.create.isSuccessful);
  const createPropertyError = useSelector(propertySelectors.create.error);

  const propertyFeatures = useSelector(propertyFeatureSelectors.getAll.data);
  const updatePropertyFeaturesSuccessful = useSelector(propertyFeatureSelectors.update.isUpdated);
  const propertyFeatureIsRefreshing = useSelector(propertyFeatureSelectors.getAll.isRefreshing);

  useEffect(() => {
    if (updatePropertyFeaturesSuccessful && propertyFeatureIsRefreshing && propertyId) {
      onGetPropertyFeatures(propertyId);
    }
  }, [
    updatePropertyFeaturesSuccessful,
    onGetPropertyFeatures,
    propertyId,
    propertyFeatureIsRefreshing
  ]);

  useEffect(() => {
    return () => {
      onGetAllProperty();
      onResetGetProperty();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getPropertyIsIdle && propertyId) {
      onGetProperty(propertyId);
      onGetPropertyFeatures(propertyId);
    }
  }, [propertyId, onGetProperty, getPropertyIsIdle, onGetPropertyFeatures]);

  useEffect(() => {
    if (getPropertyError === null && getPropertyData) {
      onGetCompanyById(getPropertyData?.company_id || '');
    }
  }, [getPropertyError, getPropertyData, onGetCompanyById]);

  useEffect(() => {
    if (updatePropertyIsSuccessful) {
      showSnackbar(
        VariantType.success,
        `The property has been updated successfully.
        Go to the user tab to continue editing information`,
        SnackTypes.go,
        () => setTabIndex(1)
      );
      onResetUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePropertyIsSuccessful, SnackTypes, VariantType, showSnackbar]);

  useEffect(() => {
    if (location.state && (createPropertyIsSuccessful || createPropertyError)) {
      if ((location.state as LocationState)?.from === 'fromCreatePage') {
        showSnackbar(
          VariantType.success,
          `The property has been created successfully.
        Go to the user tab to continue adding information`,
          SnackTypes.go,
          () => setTabIndex(1)
        );
      }
    }
  }, [
    createPropertyIsSuccessful,
    createPropertyError,
    location.state,
    showSnackbar,
    VariantType.success,
    SnackTypes.go
  ]);

  if (companyError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message="There was an error fetching the company"
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getPropertyError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message="There was an error fetching the property"
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (updatePropertyError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message="There was an error updating the property. Make sure the information provided is correct"
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  const handleOnSave = (formData: IProperty) => {
    onUpdateProperty({ id: formData.id || '', request: { property: formData } });

    if (formData.propertyFeatures && formData.id) {
      onUpdatePropertyFeatures({
        propertyId: formData.id,
        updatedFeatures: formData.propertyFeatures
      });
    }
  };

  if (getPropertyIsLoading || companyIsLoading) {
    return <Loader />;
  }

  const defaultValues: IProperty = {
    name: getPropertyData?.name || '',
    unit: getPropertyData?.unit || 0,
    address: getPropertyData?.address || '',
    city: getPropertyData?.city || '',
    state: getPropertyData?.state || '',
    zip: getPropertyData?.zip || '',
    entity_name: getPropertyData?.entity_name || '',
    email: getPropertyData?.email || '',
    id: getPropertyData?.id || '',
    company_id: getPropertyData?.company_id || '',
    company_short_id: getPropertyData?.company_short_id || '',
    unit_is_required: getPropertyData?.unit_is_required || false,
    phone_is_required: getPropertyData?.phone_is_required || false,
    status: getPropertyData?.status || PropertyStatus.ACTIVE,
    website: getPropertyData?.website || '',
    phone: getPropertyData?.phone || '',
    identity_verification_enabled: getPropertyData?.identity_verification_enabled || false,
    identity_verification_report_image_types:
      getPropertyData?.identity_verification_report_image_types || [],
    short_id: getPropertyData?.short_id || '',
    logo: getPropertyData?.logo || '',
    supported_doctypes: getPropertyData?.supported_doctypes || {}
  };

  return (
    <DetailView back={{ to: '/properties', label: 'Go Back' }} title="Edit Property">
      <PropertyMultitab
        propertyInfoTab={
          <PropertyForm
            company={companyData}
            onSave={handleOnSave}
            defaultValues={defaultValues}
            isAdmin={isAdmin}
            isFieldDisabled={isBuildingManager || isCompanyAdmin}
            propertyFeatures={propertyFeatures || []}
            isDupDisabled={getPropertyData?.disable_dup_fd}
          />
        }
        usersTab={
          <PropertyUsersPage
            id={propertyId}
            shortId={getPropertyData?.short_id}
            companyId={getPropertyData?.company_id}
            isDisabled={getPropertyData?.status === PropertyStatus.DISABLED}
          />
        }
        selectedIndex={tabIndex}
        onSelect={(index) => typeof index === 'number' && setTabIndex(index)}
      />
    </DetailView>
  );
};

export default PropertyEditPage;
