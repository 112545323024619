import { createAsyncThunk } from '@reduxjs/toolkit';

import { IProof } from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';

const getProofsByEntryIdThunkPath = `${NAME}/getProofsByEntryId`;
export const getProofsByEntryIdThunk = createAsyncThunk(
  getProofsByEntryIdThunkPath,
  async (entryId: string) => {
    try {
      return api.getProofsByEntryId(entryId);
    } catch (error) {
      console.error(getProofsByEntryIdThunkPath, error);
      throw new Error('Failed to get proofs');
    }
  }
);

const getProofByIdThunkPath = `${NAME}/getProof`;
export const getProofByIdThunk = createAsyncThunk(
  getProofByIdThunkPath,
  async (proofId: string) => {
    try {
      return api.getProofById(proofId);
    } catch (error) {
      console.error(getProofByIdThunkPath, error);
      throw new Error('Failed to get proof');
    }
  }
);

const updateProofThunkPath = `${NAME}/updateProof`;
export const updateProofThunk = createAsyncThunk(
  updateProofThunkPath,
  async ({ id, ...proof }: { id: string } & Partial<IProof>) => {
    try {
      return api.updateProof(id, proof);
    } catch (error) {
      console.error(updateProofThunkPath, error);
      throw new Error('Failed to update proof');
    }
  }
);

const getProofTextBreakupThunkPath = `${NAME}/getTextBreakup`;
export const getProofTextBreakupThunk = createAsyncThunk(
  getProofTextBreakupThunkPath,
  async ({ id, text_breakups_file }: Partial<IProof>) => {
    if (!id || !text_breakups_file) throw new Error('No ID or breakup file URL found');

    try {
      const textFile = await fetch(text_breakups_file);
      const text = await textFile.json();
      return {
        id,
        text_breakups: text
      };
    } catch (error) {
      console.error(getProofTextBreakupThunkPath, error);
      throw new Error('Failed to get proof text breakup file');
    }
  }
);
