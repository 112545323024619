import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IAnnouncement, IAuthenticatedUser, IRejectedAction } from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';
import { fetchCurrentUser, refreshUserSession } from './services';

type StateData = {
  user: IAuthenticatedUser | null;
  status: StatusTypes;
  error?: string | null;
};

const initialState: StateData = {
  user: null,
  status: StatusTypes.IDLE,
  error: null
};

const authSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    login: () => {
      api.login();
    },
    logout: () => {
      api.logout();
      return initialState;
    },
    setCurrentUser: (state, action: PayloadAction<IAuthenticatedUser | null>) => {
      state.user = {
        ...state.user,
        first_name: action?.payload?.first_name,
        last_name: action?.payload?.last_name
      } as IAuthenticatedUser;
    },
    setSettings: (state, action: PayloadAction<IAnnouncement>) => {
      state.user = { ...state.user, settings: action.payload } as IAuthenticatedUser;
    }
  },
  extraReducers: (builder) => {
    fetchCurrentUserReducer(builder);
    refreshUserSessionReducer(builder);
  }
});

const fetchCurrentUserReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(fetchCurrentUser.pending, (state, action) => {
    const { isRefreshSessionCaller } = action.meta.arg || {};
    // used to not show the loading component every time the current session is retrieve on the log out session modal
    if (!isRefreshSessionCaller) state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(fetchCurrentUser.rejected, (state, action: IRejectedAction) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
  builder.addCase(
    fetchCurrentUser.fulfilled,
    (state, action: PayloadAction<IAuthenticatedUser>) => {
      state.status = StatusTypes.SUCCESS;
      state.user = action?.payload;
    }
  );
};

const refreshUserSessionReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(refreshUserSession.pending, (state) => {
    state.status = StatusTypes.REFRESHING;
    state.error = null;
  });
  builder.addCase(refreshUserSession.rejected, (state, action: IRejectedAction) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
  builder.addCase(refreshUserSession.fulfilled, (state) => {
    state.status = StatusTypes.SUCCESS;
  });
};

export const selectors = {
  user: (state: RootState) => state[NAME].user,
  identityVerificationEnabled: (state: RootState) =>
    state[NAME].user?.properties.filter((property) => property.identity_verification_enabled)
      ? state[NAME].user?.properties.filter((property) => property.identity_verification_enabled)
          .length > 0
      : false,
  status: (state: RootState) => state[NAME].status,
  isIdle: (state: RootState) => state[NAME].status === StatusTypes.IDLE,
  isLoading: (state: RootState) => state[NAME].status === StatusTypes.LOADING,
  error: (state: RootState) => state[NAME].error
};

export const { reducer, actions } = authSlice;
