import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { getActiveRouteOptions } from 'src/router/navOptions';
import { INavOption } from 'src/ts/interfaces/navOption';
import { useFrameworkUI } from '../../../features/frameworkUI/hooks';
import NavItem, { NavItemIcons, NavItemTarget } from '../../atoms/NavItem';
import Text, { TextAlign, TextColor, TextVariant } from '../../atoms/Text';
import Modal, { ModalVariant } from '../Modal';
import {
  Button,
  ButtonIcon,
  ItemList,
  Logo,
  Policies,
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  UnorderedList,
  Version
} from './styles';
const PUBLIC_URL = process.env.PUBLIC_URL;

export type UserInformation = {
  name: string;
  logo: string;
};

export type NavSidebarProps = {
  menuItems: INavOption[];
  user: UserInformation;
  version: string;
  onLogout: () => void;
};

const NavSidebar: React.FC<NavSidebarProps> = (props: NavSidebarProps) => {
  const { menuItems, user: userInfo, version, onLogout } = props;

  const user = useSelector(authSelectors.user);
  const { isOpenSidebar: isOpen, onSetIsOpenSidebar } = useFrameworkUI();

  const [showModalLogout, setShowModalLogout] = useState(false);

  const onCloseLogout = () => {
    setShowModalLogout(true);
  };

  const isOpenIcon = isOpen ? 'icon-circle-close.svg' : 'icon-circle-open.svg';

  return (
    <Sidebar isOpen={isOpen} aria-expanded={isOpen}>
      <Modal
        title="Logout"
        text="Are you sure you want to logout?"
        variant={ModalVariant.none}
        showModal={showModalLogout}
        setShowModal={setShowModalLogout}
        labelButtonConfirm="Logout"
        labelButtonCancel="Cancel"
        onConfirm={onLogout}
      />
      <SidebarHeader>
        <Logo
          src={`${PUBLIC_URL}/assets/images/${isOpen ? 'logo-white.svg' : 'isotype-white.svg'}`}
        />
        <Button
          name="buttonToggleSidebar"
          id="buttonToggleSidebar"
          aria-pressed="true"
          onClick={onSetIsOpenSidebar}
        >
          <ButtonIcon
            src={`${PUBLIC_URL}/assets/images/${isOpenIcon}`}
            alt={`${isOpen ? 'iconOpenSidebar' : 'iconCloseSidebar'}`}
          />
        </Button>
      </SidebarHeader>
      <SidebarBody>
        <UnorderedList data-id="navMain">
          {menuItems
            .filter(
              (item: INavOption) => item.isVisible && user && item.permissions.includes(user.role)
            )
            .map((item: INavOption) => {
              return (
                <ItemList key={item.name}>
                  <NavItem
                    name={item.name}
                    label={item.label}
                    icon={item.icon}
                    to={item.to}
                    target={item.target}
                  />
                </ItemList>
              );
            })}
        </UnorderedList>
      </SidebarBody>
      <SidebarFooter>
        <UnorderedList data-id="navProfile">
          <ItemList>
            <NavItem
              name="ButtonProfile"
              label={userInfo.name}
              icon={NavItemIcons.none}
              customIcon={userInfo.logo}
              to="/user-profile"
              target={NavItemTarget.self}
            />
          </ItemList>
          <ItemList>
            <NavItem
              name="ButtonLogout"
              label="Logout"
              icon={NavItemIcons.logout}
              onClick={onCloseLogout}
            />
          </ItemList>
        </UnorderedList>
        <Version data-id="versionText">
          <Text align={TextAlign.center} color={TextColor.white} variant={TextVariant.small}>
            {`v ${version}`}
          </Text>
        </Version>
        {isOpen && (
          <Policies data-id="policiesText">
            <Text
              align={TextAlign.center}
              color={TextColor.white}
              variant={TextVariant.small}
              isBold={false}
              isStretched
            >
              View{' '}
              <a
                href="https://snappt.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://snappt.com/terms-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </Text>
          </Policies>
        )}
      </SidebarFooter>
    </Sidebar>
  );
};

NavSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired
  }).isRequired,
  version: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired
};

NavSidebar.defaultProps = {
  user: {
    name: "Name's Company",
    logo: '/'
  },
  version: '2.0.0',
  menuItems: getActiveRouteOptions(),
  onLogout: () => null
};

export default NavSidebar;
