import { useCallback } from 'react';
import { useSelector } from 'react-redux';
// Redux
import {
  actions,
  selectors as propertyFeatureSelectors
} from 'src/features/propertyFeature/propertyFeatureSlice';

// Hooks
import { useAppDispatch } from 'src/store';
import { getAll, propertyFeatureUpdateThunk } from '../services';
// Types
import { PropertyFeatureUpdatePayload } from 'src/ts/interfaces/propertyFeature';

const useProperty = () => {
  const dispatch = useAppDispatch();

  const propertyFeatures = useSelector(propertyFeatureSelectors.getAll.data);
  const propertyFeatureIsIdle = useSelector(propertyFeatureSelectors.getAll.isIdle);

  const onGetAll = useCallback(
    (propertyId: string) => {
      dispatch(getAll(propertyId));
    },
    [dispatch]
  );

  const onResetGetAll = useCallback(() => {
    dispatch(actions.resetGetAll());
  }, [dispatch]);

  const onUpdatePropertyFeatures = useCallback(
    (payload: PropertyFeatureUpdatePayload) => {
      dispatch(propertyFeatureUpdateThunk(payload));
    },
    [dispatch]
  );

  const getIsPropertyFeatureActive = useCallback(
    (code: string) => {
      return (
        propertyFeatures?.some((feature) => feature.code === code && feature.state === 'enabled') ||
        false
      );
    },
    [propertyFeatures]
  );

  return {
    onGetAll,
    onResetGetAll,
    onUpdatePropertyFeatures,
    getIsPropertyFeatureActive,
    propertyFeatureIsIdle
  };
};

export default useProperty;
