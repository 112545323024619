// Vendor
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonColor, ButtonType, ButtonVariant } from 'src/components/atoms/Button';
import ButtonLink, { ButtonLinkColor, ButtonLinkVariant } from 'src/components/atoms/ButtonLink';
import { Checkbox } from 'src/components/atoms/Checkbox';
import Gap from 'src/components/atoms/Gap';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { AutoSuggest } from 'src/components/molecules/AutoSuggest';
import CopyButton from 'src/components/molecules/CopyButton';
import { ControlledDropdown } from 'src/components/molecules/Dropdown';
import { FileInputField } from 'src/components/molecules/FileInputField';
import { InputNumber } from 'src/components/molecules/InputNumber';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import { CompanyDropdown, Container } from './styles';

// Constants
import { STATES } from 'src/constants/states';

// Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import useSearchCompany from 'src/features/invitation/hooks/useSearchCompany';
import { useDebounce, useFeatureFlag } from 'src/hooks';

// Helpers
import { getPrivateLink, getPublicLink } from 'src/features/DUP/helpers';
import {
  formatLabelValue,
  formatPhone,
  getDifferences,
  noWhiteSpacesRegex,
  onlyNumbersRegex,
  onlyRequestedSymbols,
  onlySpecialCharactersRegex,
  usZipCodeRegex,
  validateName,
  websiteRegex
} from 'src/helpers';

// Redux
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

// Enums
import { FeatureFlagKey, PropertyStatus } from 'src/ts/enums';

// Types
import { RadioButtonData } from 'components/atoms/RadioButton';
import { getOldDocumentTypes } from 'src/features/DUP/helpers/getDocumentTypes';
import { IDENTITY_VERIFICATION_REPORT_IMAGE_TYPES } from 'src/features/property';
import { Feature } from 'src/ts/enums/feature';
import {
  ICompany,
  IProperty,
  IPropertyFeature,
  IPropertySupportedDoctypes,
  IValueLabel
} from 'src/ts/interfaces';

export type PropertyFormProps = {
  defaultValues?: IProperty;
  company?: ICompany | null;
  onSave: (formData: IProperty) => void;
  isCreateForm?: boolean;
  isAdmin?: boolean;
  isFieldDisabled?: boolean;
  propertyFeatures?: IPropertyFeature[];
  isDupDisabled?: boolean;
};

const PropertyForm: React.FC<PropertyFormProps> = (props: PropertyFormProps) => {
  const {
    defaultValues,
    company,
    onSave,
    isAdmin,
    isCreateForm,
    isFieldDisabled,
    propertyFeatures,
    isDupDisabled
  } = props;

  const {
    onSearch: onSearchCompany,
    getAll: companies,
    onResetGetAll: onResetSearchCompany
  } = useSearchCompany();

  const { isAdminOrAccountRepresentative } = useUserRoles();
  const isLoading = useSelector(propertySelectors.getOne.isLoading);

  const [searchCompany, setSearchCompany] = useState('');

  const displayIncomeVerificationPropertyToggle = useFeatureFlag(
    FeatureFlagKey.INCOME_VERIFICATION_PROPERTY_TOGGLE
  );

  const showPropertyFeatures = isAdminOrAccountRepresentative && propertyFeatures;

  const methods = useForm<IProperty>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onSubmit'
  });

  const { getValues } = methods;

  const debouncedSearchCompany = useDebounce(searchCompany, 800);
  useEffect(() => {
    if (debouncedSearchCompany.length) {
      onSearchCompany(debouncedSearchCompany.trim());
    }
  }, [debouncedSearchCompany]);

  const handleOnSave = (formData: IProperty) => {
    // Non-admins use their current company id to create new properties
    if (!isAdmin && company?.short_id) {
      formData.company_short_id = company.short_id;
    }

    // Company dropdown only selects short_id so this is a hack to get the id.
    const selectedCompany = companies?.data.filter(
      (company) => company.short_id === formData.company_short_id
    )[0];
    formData.company_id = selectedCompany ? selectedCompany?.id : company?.id;
    onSave({ ...formData, phone: formatPhone(formData) });
  };

  const documentTypes = getOldDocumentTypes();

  const propertyShortId = getValues('short_id');
  const isPropertyDisabled =
    defaultValues?.status === 'DISABLED' || defaultValues?.status === 'PENDING';

  const { hasDifferences: isDirty } = getDifferences(defaultValues, getValues(), ['company_id']);

  const identityVerificationReportImageTypes =
    getValues('identity_verification_report_image_types') || [];
  const defaultIdentityVerificationReportImageTypes = IDENTITY_VERIFICATION_REPORT_IMAGE_TYPES.map(
    (identityVerificationReportImageType) => {
      if (
        identityVerificationReportImageTypes.length === 0 &&
        identityVerificationReportImageType.value === 'none'
      )
        return { ...identityVerificationReportImageType, isChecked: true };

      return {
        ...identityVerificationReportImageType,
        isChecked:
          identityVerificationReportImageTypes.indexOf(identityVerificationReportImageType.value) >
          -1
      };
    }
  );

  const identityVerificationReportImageTypeOnChange = (value: RadioButtonData[]) => {
    const selected = value.find((item) => item.isChecked);
    if (selected?.value && selected?.value !== 'none') {
      methods.setValue('identity_verification_report_image_types', [selected.value]);
    } else {
      methods.setValue('identity_verification_report_image_types', []);
    }
    methods.trigger('identity_verification_report_image_types');
  };

  const onPropertyFeaturesChange = (
    feature_index: number,
    isChecked: boolean,
    propertyFeatures: IPropertyFeature[]
  ) => {
    const state = isChecked ? 'enabled' : 'disabled';
    const newPropertyFeatures = propertyFeatures.map((feature, i) => {
      return feature_index === i ? { ...feature, state: state } : feature;
    });

    methods.setValue('propertyFeatures', newPropertyFeatures);
    methods.trigger('propertyFeatures');
  };

  return (
    <>
      {company?.short_id && propertyShortId && !isDupDisabled && (
        <>
          <Row gap={1} alignItems="center">
            <Text>DUP links</Text>
            <Row gap={1.5}>
              <CopyButton
                name="ApplicationLink"
                colorLink={ButtonLinkColor.primary}
                colorCopy={ButtonColor.primary}
                label="Application Link"
                to={getPublicLink(propertyShortId || '', company?.short_id || '')}
                isDisabled={isPropertyDisabled}
                showCopyButton
              />
              <CopyButton
                name="LeasingTeamLink"
                colorLink={ButtonLinkColor.secondary}
                colorCopy={ButtonColor.secondary}
                label="Leasing Team Link"
                to={getPrivateLink(propertyShortId || '', company?.short_id || '')}
                isDisabled={isPropertyDisabled}
              />
            </Row>
          </Row>
        </>
      )}

      <Gap height={2} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleOnSave)} noValidate>
          <Container>
            <Row gap={2}>
              <Row.Col size={9}>
                <Row gap={4}>
                  <Row.Col size={1}>
                    {((isCreateForm && isAdmin) || !isCreateForm) && (
                      <CompanyDropdown>
                        <Controller
                          name="company_id"
                          control={methods.control}
                          render={({ field: { name } }) => (
                            <AutoSuggest
                              name={name}
                              data={
                                formatLabelValue({
                                  data: companies.data,
                                  value: 'short_id',
                                  label: 'name'
                                }) as IValueLabel[]
                              }
                              placeholder="Company"
                              initialLabel={isCreateForm ? '' : company?.name}
                              onChange={({ value }) => {
                                setSearchCompany(value.toString());
                              }}
                              onSelect={(item) => {
                                methods.setValue('company_short_id', item.value);
                                methods.setValue('company_id', item.value);
                              }}
                              onClear={() => {
                                onResetSearchCompany();
                                methods.setValue('company_short_id', '');
                                methods.setValue('company_id', '');
                              }}
                              isLoading={companies.isLoading}
                              hasError={companies.error !== null}
                              isDisabled={!isCreateForm}
                              config={{
                                required: 'Complete this field'
                              }}
                            />
                          )}
                        />
                      </CompanyDropdown>
                    )}
                  </Row.Col>
                </Row>
                <Row gap={1}>
                  <Row.Col size={1}>
                    <InputText
                      placeholder="Property Name"
                      name="name"
                      type={InputTextType.text}
                      defaultValue={defaultValues?.name}
                      config={{
                        required: 'Complete this field',
                        minLength: {
                          value: 3,
                          message: 'Property name should have at least three characters'
                        },
                        maxLength: {
                          value: 80,
                          message: 'Property name should have 80 characters maximum'
                        },
                        validate: {
                          noSpecialCharacters: (value) => onlyRequestedSymbols(value)
                        }
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                  <Row.Col size={1}>
                    <InputText
                      placeholder="Units"
                      name="unit"
                      type={InputTextType.text}
                      defaultValue={defaultValues?.unit?.toString()}
                      config={{
                        required: 'Complete this field',
                        maxLength: { value: 8, message: 'Unit should have 8 characters maximum' },
                        validate: {
                          onlyNumbers: (value) =>
                            onlyNumbersRegex.test(value) ||
                            'Invalid unit. Only numbers are allowed',
                          greaterThanZero: (value) =>
                            value > 0 || 'Unit number should be greater than zero'
                        }
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                </Row>
                <Row gap={1}>
                  <Row.Col size={1}>
                    <InputText
                      className="optional-field"
                      placeholder="Legal Entity Name"
                      name="entity_name"
                      defaultValue={defaultValues?.entity_name}
                      type={InputTextType.text}
                      config={{
                        minLength: {
                          value: 3,
                          message: 'Entity name should have at least three characters'
                        },
                        maxLength: {
                          value: 80,
                          message: 'Entity name should have 80 characters maximum'
                        },
                        validate: {
                          noSpecialCharacters: (value) => validateName(value)
                        }
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                  <Row.Col size={1}>
                    <InputText
                      placeholder="Address"
                      name="address"
                      defaultValue={defaultValues?.address}
                      type={InputTextType.text}
                      config={{
                        required: 'Complete this field',
                        maxLength: {
                          value: 120,
                          message: 'Address should have 120 characters maximum'
                        },
                        validate: (value) =>
                          noWhiteSpacesRegex.test(value) ||
                          'Address should not start or end with whitespaces',
                        pattern: {
                          value: onlySpecialCharactersRegex,
                          message: 'Address should not consist only of special characters'
                        }
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                </Row>
                <Row gap={1}>
                  <Row.Col size={1}>
                    <InputText
                      placeholder="City"
                      name="city"
                      defaultValue={defaultValues?.city}
                      type={InputTextType.text}
                      config={{
                        required: 'Complete this field',
                        maxLength: {
                          value: 80,
                          message: 'City name should have 80 characters maximum'
                        }
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                  <Row.Col size={1}>
                    <ControlledDropdown
                      showLabel
                      placeholder="State"
                      name="state"
                      data={STATES}
                      config={{ required: 'Complete this field' }}
                      size="md"
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                </Row>
                <Row gap={1}>
                  <Row.Col size={1}>
                    <InputText
                      placeholder="ZIP Code"
                      name="zip"
                      defaultValue={defaultValues?.zip}
                      type={InputTextType.text}
                      config={{
                        required: 'Complete this field',
                        validate: (value) => usZipCodeRegex.test(value) || 'Invalid zip code'
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                  <Row.Col size={1}>
                    <InputText
                      placeholder="Contact Email"
                      name="email"
                      defaultValue={defaultValues?.email}
                      type={InputTextType.email}
                      config={{
                        required: 'Complete this field'
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                </Row>
                <Row gap={1}>
                  <Row.Col size={1}>
                    <InputText
                      className="optional-field"
                      placeholder="Website"
                      name="website"
                      defaultValue={defaultValues?.website}
                      type={InputTextType.text}
                      config={{
                        maxLength: {
                          value: 120,
                          message: 'Website should have 120 characters maximum'
                        },
                        validate: {
                          invalidWebsiteString: (value) =>
                            value ? websiteRegex.test(value) || 'Invalid website' : undefined,
                          noWhiteSpaces: (value) =>
                            // only run validation if there is a value inserted in the field
                            value
                              ? noWhiteSpacesRegex.test(value) ||
                                'Website should not start or end with whitespaces'
                              : undefined
                        }
                      }}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                  <Row.Col size={1}>
                    <InputText
                      className="optional-field"
                      placeholder="Phone"
                      name="phone"
                      defaultValue={defaultValues?.phone}
                      type={InputTextType.tel}
                      isDisabled={isFieldDisabled}
                    />
                  </Row.Col>
                </Row>
                {isAdminOrAccountRepresentative && (
                  <Row>
                    <Row.Col>
                      <Checkbox
                        isChecked={defaultValues?.identity_verification_enabled ?? false}
                        name="identity_verification_enabled"
                        value="identity_verification_enabled"
                        onClick={({ isChecked }) => {
                          methods.setValue('identity_verification_enabled', isChecked);
                          methods.trigger('identity_verification_enabled');
                        }}
                        label="Identity Verification"
                      />
                    </Row.Col>
                  </Row>
                )}
                <Gap height={0.5} />
                {methods.getValues('identity_verification_enabled') && (
                  <Row gap={1}>
                    <Row.Col>
                      <Text>Identity Verification Report Image Type:</Text>
                    </Row.Col>
                    <Row.Col>
                      <RadioButtonGroup
                        name="identity_verification_report_image_types"
                        data={defaultIdentityVerificationReportImageTypes}
                        className="identityVerificationReportImageTypesRadioButtonGroup"
                        onChange={identityVerificationReportImageTypeOnChange}
                      />
                    </Row.Col>
                  </Row>
                )}
                <Gap height={0.5} />
                {showPropertyFeatures &&
                  propertyFeatures.map((feature, i) => {
                    if (
                      feature.code === Feature.INCOME_VERIFICATION &&
                      !displayIncomeVerificationPropertyToggle
                    ) {
                      return null;
                    }
                    return (
                      <Row key={i}>
                        <Row.Col>
                          <Checkbox
                            isChecked={feature.state === 'enabled'}
                            name={feature.name || ''}
                            value={feature.code || ''}
                            onClick={({ isChecked }) => {
                              onPropertyFeaturesChange(i, isChecked, propertyFeatures);
                            }}
                            label={feature.name || ''}
                          />
                        </Row.Col>
                      </Row>
                    );
                  })}
                <Gap height={1} />
                <Line />
                <Gap height={2} />

                <Row>
                  <Row.Col size={2}>
                    <Text variant={TextVariant.h3}>Document Upload Portal Customization</Text>
                    <Gap height={0.5} />
                    <Text variant={TextVariant.normal} color={TextColor.gray700}>
                      This customization will be reflected in the DUP’s form fields.
                    </Text>
                    <Gap height={1.5} />
                  </Row.Col>
                </Row>
                <Row>
                  <Row.Col size={1}>
                    <Gap height={1} />
                    <Text color={TextColor.secondary} variant={TextVariant.h4}>
                      Applicant Information
                    </Text>
                    <Gap height={1} />

                    <Controller
                      name="phone_is_required"
                      control={methods.control}
                      render={({ field: { name } }) => (
                        <Checkbox
                          isChecked={defaultValues?.phone_is_required ?? true}
                          value={name}
                          name={name}
                          onClick={({ isChecked }) => {
                            methods.setValue(name, isChecked);
                            methods.trigger(name);
                          }}
                          label="Phone"
                        />
                      )}
                    />
                    <Gap height={0.5} />

                    <Controller
                      name="unit_is_required"
                      control={methods.control}
                      render={({ field: { name } }) => (
                        <Checkbox
                          isChecked={defaultValues?.unit_is_required ?? true}
                          value={name}
                          name={name}
                          onClick={({ isChecked }) => {
                            methods.setValue(name, isChecked);
                            methods.trigger(name);
                          }}
                          label="Unit Number (optional)"
                        />
                      )}
                    />
                  </Row.Col>
                  <Row.Col size={1}>
                    <Gap height={1} />
                    <Text color={TextColor.secondary} variant={TextVariant.h4}>
                      Document Uploads
                    </Text>
                    {documentTypes.map(({ key, label }) => (
                      <Fragment key={key}>
                        <Gap height={1} />
                        <Text color={TextColor.secondary}>{label} Required</Text>
                        <Gap />
                        <Controller
                          name={`supported_doctypes.${key as keyof IPropertySupportedDoctypes}`}
                          control={methods.control}
                          render={({ field: { name } }) => (
                            <InputNumber
                              value={
                                defaultValues?.supported_doctypes &&
                                defaultValues?.supported_doctypes[
                                  key as keyof IPropertySupportedDoctypes
                                ]
                                  ? (defaultValues?.supported_doctypes[
                                      key as keyof IPropertySupportedDoctypes
                                    ] as number)
                                  : 0
                              }
                              name={name}
                              onChange={(value) => {
                                methods.setValue(name, value);
                                methods.trigger(name);
                              }}
                            />
                          )}
                        />
                      </Fragment>
                    ))}
                  </Row.Col>
                </Row>
                <Row>
                  <Row.Col size={2}>
                    <Gap height={2} />
                    <Line />
                    <Gap height={2} />
                  </Row.Col>
                </Row>
              </Row.Col>
              <Row.Col size={3}>
                <FileInputField name="logo" title="Property Logo" isDisabled={isFieldDisabled} />
              </Row.Col>
            </Row>
            <Row gap={1}>
              <Button
                className="save-button"
                name="SubmitPropertyDetails"
                variant={ButtonVariant.contained}
                isDisabled={!isDirty || isLoading}
                isLoading={isLoading}
                type={ButtonType.submit}
              >
                {isLoading ? 'Saving' : 'Save'}
              </Button>
              <ButtonLink name="Cancel" variant={ButtonLinkVariant.outline} to="/properties">
                Cancel
              </ButtonLink>
            </Row>
          </Container>
        </form>
      </FormProvider>
    </>
  );
};

PropertyForm.defaultProps = {
  defaultValues: {
    address: '',
    city: '',
    state: '',
    zip: '',
    status: PropertyStatus.ACTIVE,
    unit: 1,
    company_short_id: '',
    name: '',
    identity_verification_enabled: false,
    propertyFeatures: [],
    phone_is_required: true,
    unit_is_required: true,
    supported_doctypes: {
      paystub: 2,
      bank_statement: 2,
      cash_app_statement: 0,
      credit_debit_card_statement: 0,
      department_of_veterans_affairs_benefit_letter: 0,
      investment_account: 0,
      social_security_benefits_letter: 0,
      social_security_statement: 0,
      tax_transcript: 0,
      utility_bill: 0
    },
    identity_verification_report_image_types: []
  },
  onSave: () => null,
  isCreateForm: false,
  isAdmin: true,
  isFieldDisabled: false
};

export default PropertyForm;
